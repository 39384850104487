import React from 'react';
// import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from '../utils';

class B1Intro extends React.Component {

  loadDataSource(dataSource, jsonds) {
    try {
      const titleList = dataSource.titleWrapper.children
      titleList[0].children = (
        <span>
          <b>{jsonds.title}</b>
        </span>
      )
      titleList[1].children = (
        <span>
          <p>{jsonds.subtitle}</p><br />
        </span>
      )
      const blockList = dataSource.block.children
      const list = jsonds.descriptions
      for (let i = 0; i < list.length; i++) {
        if (blockList[i]) {
          blockList[i].name = 'block' + i
          blockList[i].title.children = list[i].title
          blockList[i].content.children = list[i].content
        } else {
          blockList[i] = {
            name: 'block' + i,
            img: {
              children: 'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
              className: 'B1Intro-icon',
            },
            title: { className: 'B1Intro-title', children: list[i].title },
            content: {
              className: 'B1Intro-content',
              children: list[i].content,
            },
          }
        }
      }
      if (blockList.length > list.length)
        dataSource.block.children = blockList.slice(0, list.length)
      // console.log('business:B1Intro:dataSource', dataSource)
    } catch (err) {
      // console.log(err)
    }
    return dataSource;
  }

  getBlockChildren = (data) =>
    data.map(($item) => {
      const { ...item } = $item;
      // const { title, img, content } = item;
      const { title, content } = item;
      ['title', 'img', 'content'].forEach((key) => delete item[key]);
      return (
        <li key={item.name} {...item}>
          {/* <span {...img}>
            <img src={img.children} width="100%" alt="img" />
          </span> */}
          <h2 {...title}>{title.children}</h2>
          <div {...content}>{content.children}</div>
        </li>
      );
    });

  render() {
    const { jsonds, isMobile, ...props } = this.props;
    let { dataSource } = props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete props.dataSource;
    delete props.isMobile;
    delete props.jsonds;
    const ulChildren = this.getBlockChildren(dataSource.block.children);
    const queue = isMobile ? 'bottom' : 'left';
    // const imgAnim = isMobile
    //   ? {
    //     y: 30,
    //     opacity: 0,
    //     delay: 600,
    //     type: 'from',
    //     ease: 'easeOutQuad',
    //   }
    //   : {
    //     x: 30,
    //     opacity: 0,
    //     type: 'from',
    //     ease: 'easeOutQuad',
    //   };
    if (isMobile) dataSource.wrapper.className = dataSource.classNameMobile;
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack} component={Row}>
          <QueueAnim
            key="text"
            type={queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.textWrapper}
            component={Col}
          >
            <div key="title" {...dataSource.titleWrapper}>
              {dataSource.titleWrapper.children.map(getChildrenToRender)}
            </div>
            <QueueAnim
              component="ul"
              key="ul"
              type={queue}
              ease="easeOutQuad"
              {...dataSource.block}
            >
              {ulChildren}
            </QueueAnim>
          </QueueAnim>
          {/* <TweenOne
            key="img"
            animation={imgAnim}
            resetStyle
            {...dataSource.img}
            component={Col}
          >
            <img src={dataSource.img.children} width="100%" alt="img" />
          </TweenOne> */}
        </OverPack>
      </div>
    );
  }
}

export default B1Intro;
