import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender, resUrl } from '../utils';

class R1Teams extends React.PureComponent {

  loadDataSource(dataSource, jsonds) {
    try {
      // fill title info
      const titleList = dataSource.titleWrapper.children
      titleList[0].children = (
        <span>
          <b>{jsonds.title}</b>
        </span>
      )
      titleList[1].children = (
        <span>
          <p>{jsonds.subtitle}</p><br />
        </span>
      )
      // fill leader's info
      const leaderList = dataSource.blockTop.children
      const leaders = jsonds.leaders
      for (let i = 0; i < leaders.length; i++) {
        if (leaderList[i]) {
          leaderList[i].name = 'block' + i
          const tmplist = leaderList[i].titleWrapper.children
          tmplist[0].src = resUrl(leaders[i].avatar)
          tmplist[1].children = leaders[i].name
          tmplist[2].children = leaders[i].profile
          tmplist[3].children = leaders[i].description
        } else {
          leaderList[i] = {
            name: 'block' + i,
            className: 'block-top',
            md: 8,
            xs: 24,
            titleWrapper: {
              children: [
                {
                  name: 'image',
                  className: 'R1Teams-top-image',
                  src: resUrl(leaders[i].avatar),
                },
                {
                  name: 'title',
                  className: 'R1Teams-top-title',
                  children: leaders[i].name,
                },
                {
                  name: 'content',
                  className: 'R1Teams-top-job',
                  children: leaders[i].profile,
                },
                {
                  name: 'content1',
                  className: 'R1Teams-top-content',
                  children: leaders[i].description,
                },
              ],
            },
          }
        }
      }
      // fill member's info
      const memberList = dataSource.block.children
      const members = jsonds.members
      for (let i = 0; i < members.length; i++) {
        if (memberList[i]) {
          memberList[i].name = 'block' + i
          memberList[i].image.src = resUrl(members[i].avatar)
          const tmplist = memberList[i].titleWrapper.children
          tmplist[0].children = members[i].name
          tmplist[1].children = members[i].profile
          tmplist[2].children = members[i].description
        } else {
          memberList[i] = {
            name: 'block' + i,
            className: 'block',
            md: 8,
            xs: 24,
            image: {
              name: 'image',
              className: 'R1Teams-image',
              src: resUrl(members[i].avatar),
            },
            titleWrapper: {
              className: 'R1Teams-textWrapper',
              children: [
                {
                  name: 'title',
                  className: 'R1Teams-title',
                  children: members[i].name
                },
                {
                  name: 'content',
                  className: 'R1Teams-job',
                  children: members[i].profile,
                },
                {
                  name: 'content1',
                  className: 'R1Teams-content',
                  children: members[i].description,
                },
              ],
            },
          }
        }
      }
      console.log('recruitment:R1Teams:dataSource', dataSource)
    } catch (err) {
      // console.log(err)
    }
    return dataSource;
  }

  getBlockChildren = (data) =>
    data.map((item, i) => {
      const { titleWrapper, image, ...$item } = item;
      return (
        <Col key={i.toString()} {...$item}>
          <Row>
            <Col span={7}>
              <div {...image}>
                <img src={image.src} alt="img" />
              </div>
            </Col>
            <Col span={17}>
              <QueueAnim {...titleWrapper} type="bottom">
                {titleWrapper.children.map(getChildrenToRender)}
              </QueueAnim>
            </Col>
          </Row>
        </Col>
      );
    });

  getBlockTopChildren = (data) =>
    data.map((item, i) => {
      const { titleWrapper, ...$item } = item;
      return (
        <Col key={i.toString()} {...$item}>
          {titleWrapper.children.map(getChildrenToRender)}
        </Col>
      );
    });

  render() {
    const { jsonds, isMobile, ...props } = this.props;
    let { dataSource } = props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete props.dataSource;
    delete props.isMobile;
    delete props.jsonds;
    const listChildren = this.getBlockChildren(dataSource.block.children);
    const listTopChildren = this.getBlockTopChildren(
      dataSource.blockTop.children
    );
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          <OverPack {...dataSource.OverPack}>
            <QueueAnim type="bottom" key="tween" leaveReverse>
              <QueueAnim
                type="bottom"
                key="blockTop"
                {...dataSource.blockTop}
                component={Row}
              >
                {listTopChildren}
              </QueueAnim>
              {/* <Divider key="divider" /> */}
              <QueueAnim
                type="bottom"
                key="block"
                {...dataSource.block}
                component={Row}
              >
                {listChildren}
              </QueueAnim>
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default R1Teams;
