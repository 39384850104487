import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';


class Recruitment extends React.Component {

  loadDataSource(dataSource, jsonds) {
    try {
      dataSource.title.children = (
        <span>
          <b>{jsonds.title}</b>
        </span>
      )
      dataSource.content.children = (
        <span>
          <p>{jsonds.subtitle}</p>
        </span>
      )
      // console.log('home:Recruitment:dataSource', dataSource)
    } catch (err) {
      // console.log(err)
    }
    return dataSource;
  }

  render() {
    const { jsonds, isMobile, ...props } = this.props;
    let { dataSource } = props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete props.dataSource;
    delete props.isMobile;
    delete props.jsonds;
    const animType = {
      queue: isMobile ? 'bottom' : 'right',
      one: isMobile
        ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
        : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
    };
    let dsw = dataSource.wrapper;
    if (isMobile) dsw.className = dataSource.classNameMobile;
    return (
      <div {...props} {...dsw}>
        <OverPack {...dataSource.OverPack} component={Row}>
          <TweenOne
            key="img"
            animation={animType.one}
            resetStyle
            {...dataSource.imgWrapper}
            component={Col}
            componentProps={{
              md: dataSource.imgWrapper.md,
              xs: dataSource.imgWrapper.xs,
            }}
          >
            <span>
              {/* <span {...dataSource.img}>
            <img src={dataSource.img.children} width="100%" alt="img" /> */}
            </span>
          </TweenOne>
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.textWrapper}
            component={Col}
            componentProps={{
              md: dataSource.textWrapper.md,
              xs: dataSource.textWrapper.xs,
            }}
          >
            <p key="h1" {...dataSource.title}>
              <a href='/recruitment' className='Recruitment-title-a'>{dataSource.title.children}</a>
            </p>
            <div key="p" {...dataSource.content}>
              <a href='/recruitment' className='Recruitment-title-a'>{dataSource.content.children}</a>
            </div>
          </QueueAnim>
        </OverPack>
      </div>
    );
  }
}
export default Recruitment;