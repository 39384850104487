/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import I1Banner from './I1Banner';
import I1News from './I1News';

import { I1BannerDataSource, I1NewsDataSource } from './data.source';
import './less/style.less';
import { getDataSource } from '../utils';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Infos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      jsonData: {},
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // 加载数据
    getDataSource('infomation').then((data) => {
      this.setState({ jsonData: data })
      // console.log("business:jsonData:", this.state.jsonData)
    })
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 300);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const { jsonData, isMobile, show } = this.state;
    const children = [
      <I1Banner
        id="I1Banner"
        key="I1Banner"
        dataSource={I1BannerDataSource}
        jsonds={jsonData.I1BannerDs}
        isMobile={isMobile}
      />,
      <I1News
        id="I1News"
        key="I1News"
        dataSource={I1NewsDataSource}
        jsonds={jsonData.I1NewsDs}
        isMobile={isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
