import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import { getChildrenToRender } from '../utils';
import { isImg } from '../utils';

class Footer extends React.Component {
  static defaultProps = {
    className: 'Footer',
  };

  loadDataSource(dataSource, jsonds) {
    try {
      const infoList = dataSource.block.children[0].childWrapper.children
      const infos = jsonds.infos
      if (infoList[0]) infoList[0].children = infos.name
      else infoList[0] = { name: 'content0', children: infos.name }
      if (infoList[1]) infoList[1].children = infos.email
      else infoList[1] = { name: 'content0', children: infos.email }
      if (infoList[2]) infoList[2].children = infos.contact
      else infoList[2] = { name: 'content0', children: infos.contact }
      if (infoList[3]) infoList[3].children = infos.address
      else infoList[3] = { name: 'content0', children: infos.address }
      const copyright = jsonds.copyright
      dataSource.copyright.children = (
        <span>
          <span className='ft_cp'>{copyright.years}</span><b className='ft_cp'>{copyright.name}</b> {copyright.suffix}
        </span>
      )
      dataSource.copyright.icp_ba = (
        <span>
          <a className='ft_cp' href={copyright.icp_url} target='_blank' rel="noopener noreferrer">{copyright.icp_no}</a>
        </span>
      )
      dataSource.copyright.ga_ba = (
        <span>
          <a className='ft_cp' href={copyright.ga_url} target='_blank' rel="noopener noreferrer">{copyright.ga_ba}</a>
        </span>
      )
      const list = copyright.statement
      dataSource.copyright.shenming = (
        <span>{
          list.map((elem, i) => {
            return (
              <a className='ft_cp' key={"cpsm" + i} href={elem.url} target='_blank' rel="noopener noreferrer">{elem.text}</a>
            )
          })
        }</span>
      )
      // console.log('home:Footer:dataSource', dataSource)
    } catch (err) {
      // console.log(err)
    }
    return dataSource;
  }

  getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === 'string' &&
              title.children.match(isImg) ? (
              <img src={title.children} width="100%" alt="img" />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>
            {childWrapper.children.map(getChildrenToRender)}
          </div>
        </Col>
      );
    });

  render() {
    const { jsonds, isMobile, ...props } = this.props;
    let { dataSource } = props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete props.dataSource;
    delete props.isMobile;
    delete props.jsonds;
    const childrenToRender = this.getLiChildren(dataSource.block.children);
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            {...dataSource.block}
          >
            {childrenToRender}
          </QueueAnim>
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyrightWrapper}
          >
            <div {...dataSource.copyrightPage}>
              <div {...dataSource.copyright}>
                {dataSource.copyright.children}
                {isMobile && (<br />)}
                {dataSource.copyright.icp_ba}
                {isMobile && (<br />)}
                {dataSource.copyright.ga_ba}
                {isMobile ? (<br />) : (<b className='ft_cp'>|</b>)}
                {dataSource.copyright.shenming}
                <br /><a className='ft_cp_a' href='http://www.xjssd.com' target='_blank' rel="noopener noreferrer">获取到当前网站地址为:{window.location.origin} 正确地址为:http://www.xjssd.com</a>
              </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer;
