import React, { Component } from 'react';
import { enquireScreen } from 'enquire-js';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './home/Header.jsx';
import Footer from './home/Footer.jsx';
import Home from './home/index.jsx';
import Infos from './infomation/index.jsx';
import Recruitment from './recruitment/index.jsx';
import Business from './business/index.jsx';
import About from './about/index.jsx';
import { getDataSource } from './utils.js';

import {
  NavDataSource,
  FooterDataSource,
} from './home/data.source.js';


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      jsonData: {},
    };
  }
  componentDidMount() {
    // 加载数据
    getDataSource('home').then((data) => {
      this.setState({ jsonData: data })
    })
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    const { jsonData, isMobile } = this.state
    return (
      <Router>
        <Header id="main_nav" key="main_nav"
          dataSource={NavDataSource}
          isMobile={isMobile}
          jsonds={jsonData.NavDs}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/business" element={<Business />} />
          <Route path="/infos" element={<Infos />} />
          <Route path="/recruitment" element={<Recruitment />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer id="main_footer" key="main_footer"
          dataSource={FooterDataSource}
          isMobile={isMobile}
          jsonds={jsonData.FooterDs}
        />
      </Router>
    );
  }
}