import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import VideoPlay from 'react-sublime-video';
import { getChildrenToRender } from '../utils';

class B1Video extends React.PureComponent {

  loadDataSource(dataSource, jsonds) {
    try {
      const titleList = dataSource.titleWrapper.children
      titleList[0].children = (
        <span>
          <b>{jsonds.title}</b>
        </span>
      )
      titleList[1].children = (
        <span>
          <p>{jsonds.subtitle}</p><br />
        </span>
      )
      dataSource.video.children = {
        video: jsonds.video.src,
        image: jsonds.video.image,
      }
      // console.log('business:B1Video:dataSource', dataSource)
    } catch (err) {
      // console.log(err)
    }
    return dataSource;
  }

  render() {
    const { jsonds, isMobile, ...props } = this.props;
    let { dataSource } = props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete props.dataSource;
    delete props.isMobile;
    delete props.jsonds;
    const animation = {
      y: '+=30',
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    };
    const videoChildren = dataSource.video.children.video;
    const { wrapper, wrapper_m } = dataSource;
    const wp = isMobile ? wrapper_m : wrapper;
    const videoNameArray = videoChildren.split('.');
    const type = videoNameArray[videoNameArray.length - 1];
    return (
      <div {...props} {...wp}>
        <div {...dataSource.page}>
          <div key="title" {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          <OverPack {...dataSource.OverPack}>
            <TweenOne
              key="video"
              animation={{ ...animation, delay: 300 }}
              {...dataSource.video}
            >
              {isMobile ? (
                <video
                  width="100%"
                  loop
                  controls
                  poster={dataSource.video.children.image}
                >
                  <source src={videoChildren} type={`video/${type}`} />
                  <track kind="captions" />
                </video>
              ) : (
                <VideoPlay
                  loop
                  width="100%"
                  poster={dataSource.video.children.image}
                >
                  <source src={videoChildren} type={`video/${type}`} />
                </VideoPlay>
              )}
            </TweenOne>
          </OverPack>
        </div>
      </div>
    );
  }
}
export default B1Video;
