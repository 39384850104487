import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from '../utils';

class A1History extends React.PureComponent {

  loadDataSource(dataSource, jsonds) {
    try {
      // fill title info
      const titleList = dataSource.titleWrapper.children
      titleList[0].children = (
        <span>
          <b>{jsonds.title}</b>
        </span>
      )
      // fill job's info
      const eventList = dataSource.block.children
      const list = jsonds.events
      for (let i = 0; i < list.length; i++) {
        if (eventList[i]) {
          eventList[i].name = 'block' + i
          const tmp = eventList[i].children
          tmp.time.children = list[i].time
          tmp.title.children = list[i].title
          tmp.content.children = list[i].desc
        } else {
          eventList[i] = {
            name: 'block' + i,
            className: 'block-wrapper',
            playScale: 0.3,
            children: {
              textWrapper: { className: 'text-wrapper' },
              icon: {
                className: 'block-icon',
                children:
                  'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
              },
              time: {
                className: 'block-time',
                children: (
                  <span>
                    <p>{list[i].time}</p>
                  </span>
                ),
              },
              title: {
                className: 'block-title',
                children: (
                  <span>
                    <p>{list[i].title}</p>
                  </span>
                ),
              },
              content: {
                className: 'block-content',
                children: (
                  <span>
                    <p>{list[i].desc}</p>
                  </span>
                ),
              },
            },
          }
        }
      }
      if (eventList.length > list.length)
        dataSource.block.children = eventList.slice(0, list.length)
      // console.log('about:A1History:dataSource', dataSource)
    } catch (err) {
      // console.log(err)
    }
    return dataSource;
  }

  getBlockChildren = (block, i) => {
    const { isMobile } = this.props;
    // if (isMobile) block.className = block.classNameMobile;
    const item = block.children;
    const textWrapper = (
      <QueueAnim
        key="text"
        leaveReverse
        delay={isMobile ? [0, 100] : 0}
        {...item.textWrapper}
      >
        <div key="time" {...item.time}>
          {item.time.children}
        </div>
        <h2 key="title" {...item.title}>
          <i {...item.icon}>
            <img src={item.icon.children} alt="img" />
          </i>
          {item.title.children}
        </h2>
        <div key="p" {...item.content}>
          {item.content.children}
        </div>
      </QueueAnim>
    );
    return (
      <OverPack key={i.toString()} {...block}>
        {textWrapper}
        {/* {isMobile && textWrapper}
        <QueueAnim
          className="image-wrapper"
          key="image"
          type={isMobile ? 'right' : 'bottom'}
          leaveReverse
          delay={isMobile ? [100, 0] : 0}
          {...item.imgWrapper}
        >
          <div key="image" {...item.img}>
            <img src={item.img.children} alt="img" />
          </div>
          <div key="name" className="name-wrapper">
            <div key="name" {...item.name}>
              {item.name.children}
            </div>
            <div key="post" {...item.post}>
              {item.post.children}
            </div>
          </div>
        </QueueAnim>

        {!isMobile && textWrapper} */}
      </OverPack>
    );
  };

  render() {
    const { jsonds, isMobile, ...props } = this.props;
    let { dataSource } = props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete props.dataSource;
    delete props.isMobile;
    delete props.jsonds;
    const children = dataSource.block.children.map(this.getBlockChildren);
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          <div {...dataSource.block}>{children}</div>
        </div>
      </div>
    );
  }
}

export default A1History;
