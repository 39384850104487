import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { getChildrenToRender } from '../utils';

class Business extends React.PureComponent {

  loadDataSource(dataSource, jsonds) {
    try {
      const dataList = dataSource.childWrapper.children
      if (dataList[0]) {
        dataList[0].children = (
          <span>
            <b>{jsonds.title}</b>
          </span>
        )
      } else {
        dataList[0] = {
          name: 'title',
          children: (
            <span>
              <b>{jsonds.title}</b>
            </span>
          ),
          className: 'business-title',
        }
      }
      if (dataList[1]) {
        dataList[1].children = (
          <span>
            <p>{jsonds.subtitle}</p>
          </span>
        )
      } else {
        dataList[1] = {
          name: 'explain',
          className: 'business-explain',
          children: (
            <span>
              <p>{jsonds.subtitle}</p>
            </span>
          ),
        }
      }
      if (dataList[2]) {
        dataList[2].children = (
          <span>
            <p>{jsonds.content}</p>
          </span>
        )
      } else {
        dataList[2] = {
          name: 'content',
          className: 'business-content',
          children: (
            <span>
              <p>{jsonds.content}</p>
            </span>
          ),
        }
      }
      if (dataList[3]) {
        dataList[3].children.href = jsonds.button.url
        dataList[3].children.children = (
          <span>
            <p>{jsonds.button.text}</p>
          </span>
        )
      } else {
        dataList[3] = {
          name: 'button',
          className: 'business-button-wrapper',
          children: {
            href: jsonds.button.url,
            className: 'business-button',
            type: 'primary',
            children: (
              <span>
                <p>{jsonds.button.text}</p>
              </span>
            ),
          },
        }
      }
      // console.log('home:Business:dataSource', dataSource)
    } catch (err) {
      // console.log(err)
    }
    return dataSource;
  }

  render() {
    const { jsonds, ...tagProps } = this.props;
    let { dataSource } = this.props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    delete tagProps.jsonds;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          {/* <div  /> */}
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.childWrapper}
            componentProps={{
              md: dataSource.childWrapper.md,
              xs: dataSource.childWrapper.xs,
            }}
          >
            {dataSource.childWrapper.children.map(getChildrenToRender)}
          </QueueAnim>
          <TweenOne key="title"
            animation={animType.one}
            {...dataSource.image}
            componentProps={{
              md: dataSource.image.md,
              xs: dataSource.image.xs,
            }}
          >
            {/* <img src={dataSource.image.children} width="100%" alt="img" /> */}
            <div id="biz_pic" />
          </TweenOne>
        </div>
      </div>
    );
  }
}
export default Business;
