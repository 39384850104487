import React from 'react';
export const A1BannerDataSource = {
  classNameMobile: 'home-page-wrapper A1Banner-wrapper bg6_m',
  OverPack: {
    className: 'home-page-wrapper A1Banner-wrapper bg6',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      {
        name: 'title',
        children: (
          <span>
            <b>企业标语、宣传图</b>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>副标题</p>
          </span>
        ),
        className: 'title-content',
      },
      // {
      //   name: 'content2',
      //   children: (
      //     <span>
      //       <p>222222</p>
      //     </span>
      //   ),
      //   className: 'title-content',
      // },
    ],
  },
};
export const A1IntroDataSource = {
  classNameMobile: 'home-page-wrapper R1Intro-wrapper bg7_m',
  OverPack: {
    className: 'home-page-wrapper R1Intro-wrapper bg7',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>企业文化</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>企业文化介绍</p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>
              企业规模和团队介绍
              <br />
            </p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const A1HistoryDataSource = {
  wrapper: {
    className: 'home-page-wrapper A1History-wrapper m1etn3fayle-editor_css',
  },
  page: { className: 'home-page A1History' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>发展历程</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block0',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          textWrapper: { className: 'text-wrapper' },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
          },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>2024年X月XX日</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>事件名</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>事件内容描述</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          textWrapper: { className: 'text-wrapper' },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>时间-年月日</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>事件名</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>事件内容描述</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          textWrapper: { className: 'text-wrapper' },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>时间-年月日</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>事件名</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>事件内容描述</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          textWrapper: { className: 'text-wrapper' },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
          },
          name: { className: 'block-name', children: '姓名' },
          post: { className: 'block-post', children: '公司 职位' },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>时间-年月日</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>事件名</p>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>事件内容描述</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
