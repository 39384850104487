import React from 'react';
export const NavDataSource = {
  isScrollLink: true,
  wrapper: { className: 'header home-page-wrapper m0q9wvlvhom-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header-logo',
  },
  LinkMenu: {
    className: 'header-menu',
    children: [
      {
        name: 'linkNav',
        to: '/home',
        children: '首页',
        className: 'menu-item',
      },
      {
        name: 'linkNav~m0q9skj8w5',
        to: '/business',
        children: '业务中心',
        className: 'menu-item',
      },
      {
        name: 'linkNav~m0q9slebcyp',
        to: '/infos',
        children: '新闻资讯',
        className: 'menu-item',
      },
      {
        name: 'linkNav~m0q9slzgtx',
        to: '/recruitment',
        children: '人才招聘',
        className: 'menu-item',
      },
      {
        name: 'linkNav~m0q9smkb8rs',
        to: '/about',
        children: '关于我们',
        className: 'menu-item',
      },
    ],
  },
  mobileMenu: { className: 'header-mobile-menu' },
};
export const BannerDataSource = {
  wrapper: { className: 'Banner' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'Banner-text-wrapper blurfilter' },
        bg: { className: 'bg bg0' },
        bg_m: { className: 'bg bg0_m' },
        title: {
          className: 'Banner-title',
          src: 'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
          children: "标题A/宣传图片A"
        },
        content: {
          className: 'Banner-content m0qfmi528hn-editor_css',
          children: (
            <p>
              宣传文案A宣传文案A宣传文案A宣传文案A宣传文案A宣传文案A宣传文案A宣传文案A宣传文案A宣传文案A
            </p>
          ),
        },
        button: { className: 'Banner-button', children: 'Learn More' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'Banner-text-wrapper blurfilter' },
        bg: { className: 'bg bg1' },
        bg_m: { className: 'bg bg1_m' },
        title: {
          className: 'Banner-title',
          src: 'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
          children: "标题B/宣传图片B"
        },
        content: {
          className: 'Banner-content m0qfmi528hn-editor_css',
          children: (
            <span>
              <p>
                宣传文案B宣传文案B宣传文案B宣传文案B宣传文案B宣传文案B宣传文案B宣传文案B宣传文案B宣传文案B宣传文案B宣传文案B<br />
              </p>
            </span>
          ),
        },
        button: { className: 'Banner-button', children: 'Learn More' },
      },
    ],
  },
};
export const BusinessDataSource = {
  wrapper: { className: 'home-page-wrapper business bg_biz' },
  page: { className: 'home-page business-page' },
  childWrapper: {
    className: 'business-title-wrapper blurfilter',
    md: 9,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <b>板块名称</b>
          </span>
        ),
        className: 'business-title',
      },
      {
        name: 'explain',
        className: 'business-explain',
        children: (
          <span>
            <p>标语/介绍</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'business-content',
        children: (
          <span>
            <p>
              简介和介绍的文案，简介和介绍的文案，简介和介绍的文案，简介和介绍的文案，简介和介绍的文案，简介和介绍的文案，简介和介绍的文案。
            </p>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'business-button-wrapper',
        children: {
          href: '/business',
          className: 'business-button',
          type: 'primary',
          children: (
            <span>
              <p>了解详情</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'business-image',
    md: 9,
    xs: 24,
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
  },
};
export const InfomationDataSource = {
  classNameMobile: 'home-page-wrapper Infomation-wrapper bg_m_xwzx',
  wrapper: {
    className: 'home-page-wrapper Infomation-wrapper bg_xwzx',
  },
  page: { className: 'home-page Infomation' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <b>最新资讯</b>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  news: [],
  blockFirst: {
    className: 'Infomation-block-wrapper',
    item: {
      name: 'block0',
      className: 'Infomation-block-first',
      children: {
        icon: {
          className: 'Infomation-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
        },
        textWrapper: { className: 'Infomation-text' },
        title: {
          className: 'Infomation-title',
          children: (
            <span>
              <p>最新资讯</p>
            </span>
          ),
        },
        content: {
          className: 'Infomation-content',
          children: (
            <span>
              <p>
                资讯内容<br />
              </p>
            </span>
          ),
        },
      },
    },
  },
  block: {
    className: 'Infomation-block-wrapper',
    children: [
      {
        name: 'block1',
        className: 'Infomation-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'Infomation-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'Infomation-text' },
          title: {
            className: 'Infomation-title',
            children: (
              <span>
                <p>次要资讯1</p>
              </span>
            ),
          },
          content: {
            className: 'Infomation-content',
            children: (
              <span>
                <p>资讯内容</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'Infomation-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'Infomation-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'Infomation-text' },
          title: {
            className: 'Infomation-title',
            children: (
              <span>
                <p>次要资讯2</p>
              </span>
            ),
          },
          content: {
            className: 'Infomation-content',
            children: (
              <span>
                <p>资讯内容</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'Infomation-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'Infomation-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'Infomation-text' },
          title: {
            className: 'Infomation-title',
            children: (
              <span>
                <p>次要资讯3</p>
              </span>
            ),
          },
          content: {
            className: 'Infomation-content',
            children: (
              <span>
                <p>资讯内容</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'Infomation-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'Infomation-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'Infomation-text' },
          title: {
            className: 'Infomation-title',
            children: (
              <span>
                <p>次要资讯4</p>
              </span>
            ),
          },
          content: {
            className: 'Infomation-content',
            children: (
              <span>
                <p>资讯内容</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};

// 国家信息中心 http://www.sic.gov.cn/sic/res/img/bigLogo.png
// 中国公共关系协会 http://images.china.cn/images1/ch/2020pra/img/logo_1.png
// 中国交通通信信息中心 https://www.cttic.cn/template/15/center/_files/img/sy/logo.png
// 清华大学 https://www.tsinghua.edu.cn/image/logo.png
// 北京大学 https://www.pku.edu.cn/Uploads/Picture/2019/12/26/s5e04176fbbfa3.png https://www.pku.edu.cn/Uploads/Picture/2019/12/26/s5e04147ee4a83.png
// 南方科技大学 https://www.sustech.edu.cn/static/images/sustech-logo-cn.png
// 西安交通大学 https://www.xjtu.edu.cn/img/logo_pic99.png
// 东方理工高等研究院 xxxxxxxx
// 中国科学院计算技术研究所 https://www.ict.ac.cn/images/header_ict.png
// 中科南京信息高铁研究院 https://nwzimg.wezhan.cn/contents/sitefiles2042/10211275/images/42256353.png
// 江苏省未来网络创新研究院 https://www.fnii.cn/website/static/images/logo2.png
// 交叉信息核心技术研究院 xxxxxxxx
// 数据空间研究院 https://www.idata.ah.cn/assets/logo.1717384741061.png
// 中广电广播电影电视设计研究院 https://www.drft.com.cn/picture/0/2212132106303456575.png
// 电力规划设计总院 http://www.eppei.com/picture/0/2109291733523349795.png
// 中国电气装备集团有限公司 https://www.cee-group.cn/images_list/logo.png
// 联泰集群 https://www.lthpc.com/public/storage/upload/files/20240220/582737616a7cd880a264f9cbcc4b3011.png
// 视联动力 https://www.visionvera.com/static/ic_logo_color.5932066f.png
// 河南投资集团 https://res.hnic.com.cn/template/hnic/resource/images/logo1.png https://res.hnic.com.cn/template/hnic/resource/images/logo.png
// 鉴衡认证 http://www.cgc.org.cn/images/images/logo-%E5%8F%8D%E7%99%BD.png
// 砺算科技 https://28624713.s21i.faiusr.com/4/ABUIABAEGAAg48qxkAYoiNiMUjCsAjg9.png
// 面壁智能 https://www.modelbest.cn/modelbest/modelbest.4VFqzLmp.svg
// 沐曦 https://www.metax-tech.com/img/logo.svg
export const PartnersDataSource = {
  classNameMobile: 'home-page-wrapper Partners-wrapper bg_m_hzhb',
  wrapper: {
    className: 'home-page-wrapper Partners-wrapper bg_hzhb',
  },
  page: { className: 'home-page Partners' },
  OverPack: { playScale: 0.3, className: 'Partners-Overpack' },
  titleWrapper: {
    className: 'Partners-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'Partners-title-h1',
        children: (
          <span>
            <b>部分合作伙伴</b>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'Partners-title-content',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'Partners-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'Partners-block',
        children: {
          className: 'Partners-block-group',
          children: [
            // {
            //   name: 'image',
            //   className: 'Partners-block-image',
            //   children:
            //     // 'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            //     '',
            // },
            // {
            //   name: 'title',
            //   className: 'Partners-block-title',
            //   children: (
            //     <span>
            //       <p>合作伙伴1</p>
            //     </span>
            //   ),
            // },
            {
              name: 'content',
              className: 'Partners-block-content',
              children: (
                <span>
                  <img alt='logo' className='Partners-logo' src="https://www.lthpc.com/public/storage/upload/files/20240220/582737616a7cd880a264f9cbcc4b3011.png" />
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'Partners-block',
        children: {
          className: 'Partners-block-group',
          children: [
            // {
            //   name: 'image',
            //   className: 'Partners-block-image',
            //   children:
            //     // 'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            //     '',
            // },
            // {
            //   name: 'title',
            //   className: 'Partners-block-title',
            //   children: (
            //     <span>
            //       <p>合作伙伴2</p>
            //     </span>
            //   ),
            // },
            {
              name: 'content',
              className: 'Partners-block-content',
              children: (
                <span>
                  <img alt='logo' className='Partners-logo' src="https://www.visionvera.com/static/ic_logo_color.5932066f.png" />
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'Partners-block',
        children: {
          className: 'Partners-block-group',
          children: [
            // {
            //   name: 'image',
            //   className: 'Partners-block-image',
            //   children:
            //     // 'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            //     '',
            // },
            // {
            //   name: 'title',
            //   className: 'Partners-block-title',
            //   children: (
            //     <span>
            //       <p>合作伙伴3</p>
            //     </span>
            //   ),
            // },
            {
              name: 'content',
              className: 'Partners-block-content',
              children: (
                <span>
                  <img alt='logo' className='Partners-logo' src="https://www.metax-tech.com/img/logo.svg" />
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'Partners-block',
        children: {
          className: 'Partners-block-group',
          children: [
            // {
            //   name: 'image',
            //   className: 'Partners-block-image',
            //   children:
            //     // 'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            //     '',
            // },
            // {
            //   name: 'title',
            //   className: 'Partners-block-title',
            //   children: (
            //     <span>
            //       <p>合作伙伴4</p>
            //     </span>
            //   ),
            // },
            {
              name: 'content',
              className: 'Partners-block-content',
              children: (
                <span>
                  <img alt='logo' className='Partners-logo' src="https://www.tsinghua.edu.cn/image/logo.png" />
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'Partners-block',
        children: {
          className: 'Partners-block-group',
          children: [
            // {
            //   name: 'image',
            //   className: 'Partners-block-image',
            //   children:
            //     // 'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            //     '',
            // },
            // {
            //   name: 'title',
            //   className: 'Partners-block-title',
            //   children: (
            //     <span>
            //       <p>合作伙伴5</p>
            //     </span>
            //   ),
            // },
            {
              name: 'content',
              className: 'Partners-block-content',
              children: (
                <img alt='logo' className='Partners-logo' src="https://www.pku.edu.cn/Uploads/Picture/2019/12/26/s5e04176fbbfa3.png" />
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block5',
        className: 'Partners-block',
        children: {
          className: 'Partners-block-group',
          children: [
            // {
            //   name: 'image',
            //   className: 'Partners-block-image',
            //   children:
            //     // 'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            //     '',
            // },
            // {
            //   name: 'title',
            //   className: 'Partners-block-title',
            //   children: (
            //     <span>
            //       <p>合作伙伴6</p>
            //     </span>
            //   ),
            // },
            {
              name: 'content',
              className: 'Partners-block-content',
              children: (
                <span>
                  <img alt='logo' className='Partners-logo' src="http://images.china.cn/images1/ch/2020pra/img/logo_1.png" />
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block6',
        className: 'Partners-block',
        children: {
          className: 'Partners-block-group',
          children: [
            // {
            //   name: 'image',
            //   className: 'Partners-block-image',
            //   children:
            //     // 'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            //     '',
            // },
            // {
            //   name: 'title',
            //   className: 'Partners-block-title',
            //   children: (
            //     <span>
            //       <p>合作伙伴7</p>
            //     </span>
            //   ),
            // },
            {
              name: 'content',
              className: 'Partners-block-content',
              children: (
                <span>
                  <img alt='logo' className='Partners-logo' src="http://www.eppei.com/picture/0/2109291733523349795.png" />
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block7',
        className: 'Partners-block',
        children: {
          className: 'Partners-block-group',
          children: [
            // {
            //   name: 'image',
            //   className: 'Partners-block-image',
            //   children:
            //     // 'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            //     '',
            // },
            // {
            //   name: 'title',
            //   className: 'Partners-block-title',
            //   children: (
            //     <span>
            //       <p>合作伙伴8</p>
            //     </span>
            //   ),
            // },
            {
              name: 'content',
              className: 'Partners-block-content',
              children: (
                <span>
                  <img alt='logo' className='Partners-logo' src="https://res.hnic.com.cn/template/hnic/resource/images/logo.png" />
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const RecruitmentDataSource = {
  classNameMobile: 'home-page-wrapper Recruitment-wrapper bg_m_rczp',
  wrapper: {
    className: 'home-page-wrapper Recruitment-wrapper bg_rczp',
  },
  OverPack: { className: 'home-page Recruitment', playScale: 0.3 },
  imgWrapper: { className: 'Recruitment-img', md: 9, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'Recruitment-text', md: 15, xs: 24 },
  title: {
    className: 'Recruitment-title',
    children: (
      <span>
        <b>欢迎加入我们</b>
      </span>
    ),
  },
  content: {
    className: 'Recruitment-content',
    children: (
      <span>
        <p>人才招聘标语或简介0000000000000000000000000</p>
      </span>
    ),
  },
};
export const FooterDataSource = {
  wrapper: { className: 'home-page-wrapper Footer-wrapper' },
  OverPack: { className: 'Footer', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 24,
        className: 'block',
        title: {
          className: 'logo',
          // children: 'http://www.xjssd.com/owres/logotmp.svg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              // children: '新疆数算电科技技术有限责任公司',
              children: 'XXXXX科技技术有限责任公司',
            },
            {
              name: 'content0',
              // children: '公司邮箱：office@xjssd.com',
              children: '公司邮箱：xxxxx@xxxxx.com',
            },
            {
              name: 'content0',
              // children: '联系电话：0902-6720393 / 0902-6721899',
              children: '联系电话：0000-0000000 / 0000-0000000',
            },
            {
              name: 'content0',
              // children: '公司地址：新疆哈密市伊吾县创业产业园3楼310室',
              children: '公司地址：XX省XX市XX县XXXXXY楼XXX室',
            },
          ],
        },
      },
      // {
      //   name: 'block1',
      //   xs: 24,
      //   md: 6,
      //   className: 'block',
      //   title: { children: '' },
      //   childWrapper: {
      //     children: [
      //       // { name: 'link0', href: '#', children: '产品更新记录' },
      //       // { name: 'link1', href: '#', children: 'API文档' },
      //       // { name: 'link2', href: '#', children: '快速入门' },
      //       // { name: 'link3', href: '#', children: '参考指南' },
      //     ],
      //   },
      // },
      // {
      //   name: 'block2',
      //   xs: 24,
      //   md: 6,
      //   className: 'block',
      //   title: { children: '' },
      //   childWrapper: {
      //     children: [
      //       // { href: '#', name: 'link0', children: 'FAQ' },
      //       // { href: '#', name: 'link1', children: '联系我们' },
      //     ],
      //   },
      // },
      // {
      //   name: 'block3',
      //   xs: 24,
      //   md: 6,
      //   className: 'block',
      //   title: { children: '' },
      //   childWrapper: {
      //     children: [
      //       // { href: '#', name: 'link0', children: 'Ant Design' },
      //       // { href: '#', name: 'link1', children: 'Ant Motion' },
      //     ],
      //   },
      // },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        {/* ©2024 by <b>新疆数算电科技技术有限责任公司</b> All Rights */}
        <span className='ft_cp'>©2024</span><b className='ft_cp'>XXXXX科技技术有限责任公司</b> 版权所有
      </span>
    ),
    icp_no: (
      <span>
        <a className='ft_cp' href='https://beian.miit.gov.cn'>ICP备案号：XXXXXXXXXXXX</a>
      </span>
    ),
    ga_ba: (
      <span>
        <a className='ft_cp' href='/'>X公网安备：XXXXXXXXXXXX</a>
      </span>
    ),
    shenming: (
      <span>
        <a className='ft_cp' href='/'>法律声明</a>
        <a className='ft_cp' href='/'>隐私声明</a>
      </span>
    )
  },
};
