import React, { useState, useRef } from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Card, Button } from 'antd';
// import { message } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender, md5, resUrl } from '../utils';

const JobCard = ({ data, id, isMobile, isVisible, toggleVisible }) => {
  const { children: item } = data;
  const { job } = item;
  const headerFontSize = (isMobile ? '20px' : '22px')
  return (
    <Card
      title={job.name}
      className={data.className}
      extra={
        <Button onClick={() => toggleVisible(id)}>{isVisible ? "收起" : "详情"}</Button>
      }
      // bodyStyle={{ display: isVisible ? 'block' : 'none' }}
      headStyle={{ fontSize: headerFontSize }}
      style={{ maxHeight: isVisible ? '1000px' : '0px' }}
    >
      <div id={id}>
        <div className={job.className}><b>职位描述：</b>{job.desc}</div>
        {
          job.responsibility && job.responsibility.length > 0 ?
            (
              <div className={job.className}>
                <b>职位职责：</b>
                {job.responsibility.map((text, i) => {
                  return (<div key={"jres" + i}>{i + 1}.{text}</div>)
                })
                }
              </div>
            )
            : (<div></div>)
        }
        {
          job.requirements && job.requirements.length > 0 ?
            (
              <div className={job.className}>
                <b>职位要求：</b>{
                  job.requirements.map((text, i) => {
                    return (<div key={"jreq" + i}>{i + 1}.{text}</div>)
                  })
                }
              </div>
            )
            : (<div></div>)
        }
      </div>
    </Card>
  );
}
const JobList = ({ isMobile, dataSource }) => {
  const initialComponents = Array.from({ length: dataSource.children.length }, (_, i) => ({
    data: dataSource.children[i],
    id: md5(dataSource.children[i].children.job.name + dataSource.children[i].children.job.desc) + i,
    isVisible: false,
    isMobile,
  }));
  const [components, setComponents] = useState(initialComponents);
  const toggleVisible = (id) => {
    setComponents((prevComponents) =>
      prevComponents.map((component) =>
        component.id === id
          ? { ...component, isVisible: !component.isVisible }
          : component
      )
    );
  };
  return (
    <div>
      {components.map((component) => (
        <Col
          key={"jc_" + component.id.toString()}
          xd={component.data.xd}
          md={component.data.md}
          {...component.data}
        >
          <JobCard
            key={component.id}
            data={component.data}
            id={component.id}
            isMobile={component.isMobile}
            isVisible={component.isVisible}
            toggleVisible={toggleVisible}
          />
        </Col>
      ))}
    </div>
  )
}

let banclick = false
const FileCard = ({ data, id, isMobile }) => {
  const { children: item } = data;
  const { file } = item;
  const headerFontSize = (isMobile ? '12px' : '14px')
  const aRef = useRef(null);
  // const [messageApi, contextHolder] = message.useMessage();
  // const info = (msg) => {
  //   messageApi.info(msg);
  // };
  const handleDownload = () => {
    if (banclick) {
      // info('请勿重复提交')
    } else {
      if (aRef.current) {
        banclick = true
        aRef.current.click();
        setTimeout(() => {
          banclick = false
        }, 5000)
      }
    }
  };
  const image = resUrl(file.src);
  return (
    // <>
    //   {contextHolder}
    <Card
      id={id}
      title={file.name}
      className={data.className}
      onClick={handleDownload}
      // extra={
      //   <Button onClick={() => toggleVisible(id)}>下载</Button>
      // }
      headStyle={{ fontSize: headerFontSize }}
      style={{ maxHeight: '0px' }}
    >
      <a
        ref={aRef}
        href={image}
        download={file.name}
        style={{ display: 'none' }}
      >
        下载
      </a>
    </Card>
    // </>
  );
}
const FileList = ({ isMobile, dataSource }) => {
  const components = Array.from({ length: dataSource.files.length }, (_, i) => ({
    data: dataSource.files[i],
    id: md5(dataSource.files[i].children.file.name + dataSource.files[i].children.file.src) + i,
    isMobile,
  }));
  return (
    <div>
      {components.map((component) => (
        <Col
          key={"fc_" + component.id.toString()}
          xd={component.data.xd}
          md={component.data.md}
          {...component.data}
        >
          <FileCard
            key={component.id}
            data={component.data}
            id={component.id}
            isMobile={component.isMobile}
          />
        </Col>
      ))}
    </div>
  )
}
class R1Content extends React.PureComponent {

  loadDataSource(dataSource, jsonds) {
    try {
      // fill title info
      const titleList = dataSource.titleWrapper.children
      titleList[0].children = (
        <span>
          <b>{jsonds.title}</b>
        </span>
      )
      // fill job's info
      const jobList = dataSource.childWrapper.children
      const jobs = jsonds.jobs
      for (let i = 0; i < jobs.length; i++) {
        if (jobList[i]) {
          jobList[i].name = 'block' + i
          const tmp = jobList[i].children.job
          tmp.name = jobs[i].name
          tmp.desc = jobs[i].desc
          tmp.responsibility = jobs[i].responsibility
          tmp.requirements = jobs[i].requirements
        } else {
          jobList[i] = {
            name: 'block' + i,
            className: 'R1Content-block',
            md: 12,
            xs: 24,
            children: {
              className: 'R1Content-block-card',
              job: {
                className: 'R1Content-block-text',
                name: jobs[i].name,
                desc: jobs[i].desc,
                responsibility: jobs[i].responsibility,
                requirements: jobs[i].requirements,
              },
            },
          }
        }
      }
      if (jobList.length > jobs.length)
        dataSource.childWrapper.children = jobList.slice(0, jobs.length)
      // fill file's info
      const fileList = dataSource.childWrapper.files
      const files = jsonds.files
      for (let i = 0; i < files.length; i++) {
        if (fileList[i]) {
          fileList[i].name = 'block' + i
          fileList[i].md = 4
          fileList[i].xs = 24
          const tmp = fileList[i].children.file
          tmp.name = files[i].name
          tmp.src = files[i].src
        } else {
          fileList[i] = {
            name: 'block' + i,
            className: 'R1Content-file',
            md: 4,
            xs: 24,
            children: {
              className: 'R1Content-file-card',
              file: {
                className: 'R1Content-file-text',
                name: files[i].name,
                src: files[i].src,
              },
            },
          }
        }
      }
      if (fileList.length > files.length)
        dataSource.childWrapper.files = fileList.slice(0, files.length)
      // console.log('recruitment:R1Content:dataSource', dataSource)
    } catch (err) {
      // console.log(err)
    }
    return dataSource;
  }

  render() {
    const { jsonds, isMobile, ...props } = this.props;
    let { dataSource } = props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete props.dataSource;
    delete props.isMobile;
    delete props.jsonds;
    const {
      wrapper,
      titleWrapper,
      page,
      OverPack: overPackData,
      childWrapper,
    } = dataSource;
    return (
      <div {...props} {...wrapper}>
        <div {...page}>
          <div {...titleWrapper}>
            {titleWrapper.children.map(getChildrenToRender)}
          </div>
          <OverPack {...overPackData}>
            <QueueAnim
              type="bottom"
              key="block0"
              leaveReverse
              component={Row}
              componentProps={childWrapper}
            >
              <JobList isMobile={isMobile} dataSource={childWrapper} />
            </QueueAnim>
            <QueueAnim
              type="bottom"
              key="block1"
              leaveReverse
              component={Row}
              componentProps={childWrapper}
            >
              {childWrapper.files && childWrapper.files.length > 0 && (
                <FileList isMobile={isMobile} dataSource={childWrapper} />
              )}
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default R1Content;
