import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Tabs, Row } from 'antd';
// import { Icon } from '@ant-design/compatible';
import { List } from 'antd';
// import { getChildrenToRender } from '../utils';

const TabPane = Tabs.TabPane;
let dataList = [];
let category = "all";// all=所有,com=公司动态,media=媒体报道
let categoryList = []

class I1News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 1,
    };
  }

  loadDataSource(dataSource, jsonds) {
    try {
      const titleList = dataSource.block.children
      const list = jsonds.category
      categoryList = []
      for (let i = 0; i < list.length; i++) {
        categoryList.push(list[i].name)
        if (titleList[i]) {
          titleList[i].name = 'block' + i;
          titleList[i].tag.text.children = (
            <span>
              <p>{list[i].title}</p>
            </span>
          )
        } else {
          titleList[i] = {
            name: 'block' + i,
            tag: {
              className: 'I1News-tag',
              // icon: { children: 'mobile' },
              text: {
                className: 'I1News-tag-name',
                children: (
                  <span>
                    <p>{list[i].title}</p>
                  </span>
                ),
              },
            },
            content: {
              className: 'I1News-content',
              list: {
                className: "I1News-list",
              },
              text: {
                className: 'I1News-text',
              },
            },
          }
        }
      }
      if (titleList.length > list.length)
        dataSource.block.children = titleList.slice(0, list.length)
      // console.log('infomation:I1News:dataSource', dataSource)
      dataList = jsonds.news
      if (category !== "all") {
        dataList = dataList.filter(tmp => {
          return category === tmp.category;
        })
      }
      dataList.sort((a, b) => {
        let at = a.time + a.no, bt = b.time + b.no;
        try {
          at = parseFloat(at);
          bt = parseFloat(bt);
        } catch {
          at = a.time + a.no;
          bt = b.time + b.no;
        } finally {
          a.id = a.category + '_' + a.time + '.' + a.no;
        }
        return (bt < at) ? -1 : 1;
      })
      // console.log('infomation:I1News:dataList', dataList)
    } catch (err) {
      dataList = []
      // console.log(err)
    }
    return dataSource;
  }

  onChange = (key) => {
    category = categoryList[parseInt(key) - 1]
    this.setState({ current: parseFloat(key) });
  };

  getBlockChildren = (item, i) => {
    const { tag, content } = item;
    const { text, list } = content;
    // const textChildren = text.children;
    // const { icon } = tag;
    // const iconChildren = icon.children;
    const tagText = tag.text;
    return (
      <TabPane
        key={i + 1}
        tab={
          <div className={tag.className}>
            {/* <Icon type={iconChildren} className={icon.className} /> */}
            <div {...tagText}>{tagText.children}</div>
          </div>
        }
        className={item.className}
      >
        <TweenOne.TweenOneGroup
          enter={{
            y: 30,
            delay: 300,
            opacity: 0,
            type: 'from',
            ease: 'easeOutQuad',
          }}
          leave={null}
          component=""
        >
          {this.state.current === i + 1 && (
            <Row
              key="content"
              className={content.className}
              gutter={content.gutter}
            >
              <List
                className={list.className}
                pagination={{
                  pageSize: 5,
                  position: "bottom",
                  align: "center",
                }}
                dataSource={dataList}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      id={item.id}
                      className={text.className}
                      // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                      title={<p>{item.title}</p>}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </Row>
          )}
        </TweenOne.TweenOneGroup>
      </TabPane>
    );
  };

  render() {
    const { jsonds, isMobile, ...props } = this.props;
    let { dataSource } = props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete props.dataSource;
    delete props.isMobile;
    delete props.jsonds;
    const tabsChildren = dataSource.block.children.map(this.getBlockChildren);
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          {/* <div {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div> */}

          <OverPack {...dataSource.OverPack}>
            <TweenOne.TweenOneGroup
              key="tabs"
              enter={{
                y: 30,
                opacity: 0,
                delay: 200,
                type: 'from',
              }}
              leave={{ y: 30, opacity: 0 }}
              {...dataSource.tabsWrapper}
            >
              <Tabs
                key="tabs"
                onChange={this.onChange}
                activeKey={`${this.state.current}`}
                {...dataSource.block}
              >
                {tabsChildren}
              </Tabs>
            </TweenOne.TweenOneGroup>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default I1News;
