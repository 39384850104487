
import React from 'react';
import { Button } from 'antd';
const crypto = require('crypto');

// export const ENV = 1 // 0=dev 1=pre 2=prod
// export const BASE_URL = (ENV === 1 ? 'http://pre.xjssd.com' : 'http://www.xjssd.com') // http://www.xjssd.com   https://www.xjssd.com
export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;
export const DOMAIN = window.location.origin
export const HOST_NAME = window.location.hostname
export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div';
  tag = item.href ? 'a' : tag;
  let children = typeof item.children === 'string' && item.children.match(isImg)
    ? React.createElement('img', { src: item.children, alt: 'img' })
    : item.children;
  if (item.name.indexOf('image') === 0 && item.src) {
    children = React.createElement('img', { src: item.src, alt: 'img' })
  } else if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = React.createElement(Button, {
      ...item.children
    });
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children);
};
export const md5 = (input) => {
  return crypto.createHash('md5').update(input).digest('hex');
}
export const hash = (algorithm, input) => {
  return crypto.createHash(algorithm).update(input).digest('hex');
}
// 格式化当前时间为 yyyyMMddhhmmss 字符串  
export const formatDate2Str = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}
/**  
 * 生成一个指定长度的随机字符串  
 * @param {number} length - 字符串的长度  
 * @returns {string} - 生成的随机字符串  
 */
export const getRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
// 获取网站数据资源路径
export const dataUrl = (path) => {
  if (!path || path === '') return ''
  if (!path.startsWith('/')) path = '/' + path
  return DOMAIN + '/owcd' + path
}
// 获取网站静态资源路径
export const resUrl = (path) => {
  if (!path || path === '') return ''
  if (!path.startsWith('/')) path = '/' + path
  return DOMAIN + '/owres' + path
}
// 获取网站动态数据
export const getDataSource = async (name, suffix) => {
  return new Promise((resolve) => {
    if (!suffix) suffix = '.json'
    const randomStr = formatDate2Str(new Date()) + getRandomString(8)
    fetch('/owcd/' + name + suffix + '?' + randomStr)
      .then(response => response.json())
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.error('Error fetching ' + name + suffix + ' data:', error)
        return {}
      })
  });
}