import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
// import { Card } from "antd";
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender, resUrl } from '../utils';

class Infomation extends React.PureComponent {
  getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;

  loadDataSource(dataSource, jsonds) {
    try {
      const titleList = dataSource.titleWrapper.children
      titleList[0].children = (
        <span>
          <b>{jsonds.home.title}</b>
        </span>
      )
      titleList[1].children = (
        <span>
          <p>{jsonds.home.subtitle}</p><br />
        </span>
      )
      dataSource.news = jsonds.news
      dataSource.news.sort((a, b) => {
        let at = a.time + a.no, bt = b.time + b.no;
        try {
          at = parseFloat(at);
          bt = parseFloat(bt);
        } catch {
          at = a.time + a.no;
          bt = b.time + b.no;
        } finally {
          a.id = a.category + '_' + a.time + '.' + a.no;
        }
        return (bt < at) ? -1 : 1;
      })
      // console.log('home:Partners:dataSource', dataSource)
    } catch (err) {
      // console.log(err)
    }
    return dataSource;
  }

  render() {
    const { jsonds, isMobile, ...props } = this.props;
    let { dataSource } = props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete props.dataSource;
    delete props.isMobile;
    delete props.jsonds;
    let clearFloatNum = 0;
    const listData = dataSource.news;
    let first = {
      id: "",
      category: "",
      time: "",
      no: "",
      title: "",
      description: "",
      backgroundImage: "",
      detail: ""
    }
    if (listData && listData.length > 0) {
      first = listData.shift();
      if (first.description.length > 200)
        first.description = first.description.substring(0, 200) + "......"
      while (listData.length > 4) listData.pop()
      listData.forEach(tmp => {
        if (tmp.description.length > 30)
          tmp.description = tmp.description.substring(0, 30) + "......"
      })
    }
    const md = 12;
    const childrens = listData.map((item, i) => {
      const delay = isMobile ? i * 50 : this.getDelay(i, 24 / md);
      const liAnim = {
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
        delay,
      };
      const childrenAnim = { ...liAnim, x: '+=10', delay: delay + 100 };
      clearFloatNum += md;
      clearFloatNum = clearFloatNum > 24 ? 0 : clearFloatNum;
      let bgImage = resUrl(item.backgroundImage);
      delete item.backgroundImage;
      return (
        <TweenOne
          component={Col}
          animation={liAnim}
          key={"home_" + item.id}
          // {...item}
          componentProps={{ md, xs: 24 }}
          className={
            !clearFloatNum
              ? `${'Infomation-block' || ''} clear-both`.trim()
              : 'Infomation-block'
          }
        >
          {bgImage !== '' ? (
            <img src={bgImage} alt='bg'
              className={
                !clearFloatNum
                  ? `${'Infomation-block-img' || ''} clear-both`.trim()
                  : 'Infomation-block-img'
              }
            />
          ) : (
            <div className={
              !clearFloatNum
                ? `${'Infomation-block-img-n' || ''} clear-both`.trim()
                : 'Infomation-block-img-n'
            }
            />
          )}
          <div
            {...{ className: 'Infomation-text' }}
          >
            <TweenOne
              key="h2"
              animation={childrenAnim}
              component="h2"
              {...{ className: 'Infomation-title' }}
            >
              <a href='/infos#I1News' className='Infomation-title-a'>{item.title}</a>
            </TweenOne>
            <TweenOne
              key="p"
              animation={{ ...childrenAnim, delay: delay + 200 }}
              component="div"
              {...{ className: 'Infomation-content' }}
            >
              <a href='/infos#I1News' className='Infomation-title-a'>{item.description}</a>
            </TweenOne>
          </div>
        </TweenOne >
      );
    });
    //   const childObj = item.children;
    //   const delay = isMobile ? i * 50 : this.getDelay(i, 24 / item.md);
    //   const liAnim = {
    //     opacity: 0,
    //     type: 'from',
    //     ease: 'easeOutQuad',
    //     delay,
    //   };
    //   const childrenAnim = { ...liAnim, x: '+=10', delay: delay + 100 };
    //   clearFloatNum += item.md;
    //   clearFloatNum = clearFloatNum > 24 ? 0 : clearFloatNum;
    //   return (
    //     <TweenOne
    //       component={Col}
    //       animation={liAnim}
    //       key={item.name}
    //       {...item}
    //       componentProps={{ md: item.md, xs: item.xs }}
    //       className={
    //         !clearFloatNum
    //           ? `${item.className || ''} clear-both`.trim()
    //           : item.className
    //       }
    //     >
    //       <div {...childObj.textWrapper}>
    //         <TweenOne
    //           key="h2"
    //           animation={childrenAnim}
    //           component="h2"
    //           {...childObj.title}
    //         >
    //           {childObj.title.children}
    //         </TweenOne>
    //         <TweenOne
    //           key="p"
    //           animation={{ ...childrenAnim, delay: delay + 200 }}
    //           component="div"
    //           {...childObj.content}
    //         >
    //           {childObj.content.children}
    //         </TweenOne>
    //       </div>
    //     </TweenOne>
    //   );
    // });
    const dbf = dataSource.blockFirst.item;
    const dbfc = dataSource.blockFirst.item.children;
    const delay = 50;
    const liAnim = {
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
      delay,
    };
    const childrenAnim = { ...liAnim, x: '+=10', delay: delay + 100 };
    let dsw = dataSource.wrapper;
    if (isMobile) dsw.className = dataSource.classNameMobile;
    let bgImage = resUrl(first.backgroundImage);
    return (
      <div {...props}  {...dsw}>
        <div {...dataSource.page}>
          <div {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          <OverPack {...dataSource.OverPack}>
            <QueueAnim key="u" type="bottom">
              <div className='Infomation-block-wrapper'>
                <TweenOne
                  component={Col}
                  animation={{
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                    delay: 50,
                  }}
                  {...dbf}
                  componentProps={{ md: 12, xs: 24 }}
                  className={
                    !clearFloatNum
                      ? `${dbf.className || ''} clear-both`.trim()
                      : dbf.className
                  }
                >
                  {bgImage !== '' ? (
                    <img src={bgImage} alt='bg'
                      className={
                        !clearFloatNum
                          ? `${'Infomation-block-first-img' || ''} clear-both`.trim()
                          : 'Infomation-block-first-img'
                      }
                    />
                  ) : (
                    <div className={
                      !clearFloatNum
                        ? `${'Infomation-block-first-img-n' || ''} clear-both`.trim()
                        : 'Infomation-block-first-img-n'
                    }
                    />
                  )}
                  <div {...dbfc.textWrapper}>
                    <TweenOne
                      key="h2"
                      animation={childrenAnim}
                      component="h2"
                      {...dbfc.title}
                    >
                      <a href='/infos#I1News' className='Infomation-title-a'>{first.title}</a>
                    </TweenOne>
                    <TweenOne
                      key="p"
                      animation={{ ...childrenAnim, delay: 250 }}
                      component="div"
                      {...dbfc.content}
                    >
                      <a href='/infos#I1News' className='Infomation-title-a'>{first.description}</a>
                    </TweenOne>
                  </div>
                </TweenOne>
              </div>
              <TweenOne
                component={Col}
                key={"rowInCol"}
                componentProps={{ md: 12, xs: 24 }}
              >
                <Row key="row" {...dataSource.block}>
                  {childrens}
                </Row>
              </TweenOne>
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default Infomation;
