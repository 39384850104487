import React from 'react';
export const I1BannerDataSource = {
  OverPack: {
    className: 'home-page-wrapper I1Banner-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      {
        name: 'title',
        children: (
          <span>
            <b>新闻资讯</b>
          </span>
        ),
        className: 'title-banner',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>科技赋能新闻，算力编织资讯</p>
          </span>
        ),
        className: 'title-content',
      },
      // {
      //   name: 'content2',
      //   children: (
      //     <span>
      //       <p>2222222222</p>
      //     </span>
      //   ),
      //   className: 'title-content',
      // },
    ],
  },
};
export const I1NewsDataSource = {
  wrapper: { className: 'home-page-wrapper I1News-wrapper' },
  page: { className: 'home-page I1News' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      // {
      //   name: 'title',
      //   children: '科技赋能新闻，算力编织资讯网',
      //   className: 'title-h1',
      // },
      // { name: 'content', children: '科技赋能新闻，算力编织资讯' },
    ],
  },
  tabsWrapper: { className: 'I1News-tabs-wrapper' },
  block: {
    // classNames: {
    //   tag: "I1News-tag",
    //   tagName: "I1News-tag-name",
    //   list: "I1News-list",
    //   text: "I1News-text",
    // },
    children: [
      {
        name: 'block0',
        tag: {
          className: 'I1News-tag',
          // icon: { children: 'mobile' },
          text: {
            className: 'I1News-tag-name',
            children: (
              <span>
                <p>全部</p>
              </span>
            ),
          },
        },
        content: {
          className: 'I1News-content',
          list: {
            className: "I1News-list",
          },
          text: {
            className: 'I1News-text',
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'I1News-tag',
          // icon: { children: 'tablet' },
          text: {
            className: 'I1News-tag-name',
            children: (
              <span>
                <p>公司动态</p>
              </span>
            ),
          },
        },
        content: {
          className: 'I1News-content',
          list: {
            className: "I1News-list",
          },
          text: {
            className: 'I1News-text',
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'I1News-tag',
          // icon: { children: 'laptop' },
          text: {
            className: 'I1News-tag-name',
            children: (
              <span>
                <p>媒体报道</p>
              </span>
            ),
          },
        },
        content: {
          className: 'I1News-content',
          list: {
            className: "I1News-list",
          },
          text: {
            className: 'I1News-text',
          },
        },
      },
    ],
    // 新闻资讯数据
    // id: 类型+下划线+当前时间(年月日)+英文点+编号
    // category 类型: com=公司动态，media=媒体报道
    // time 当前时间格式: YYYYMMDD  如: 20241010
    // no 编号: 两位数，从'01'开始
    //     示例: com_20241010.01
    // title: 标题
    // description: 描述/简介
    // detail: 详情页面排版（暂定）
    news: [
      {
        id: "",
        category: "com",
        time: "20241010",
        no: "1",
        title: "新闻PIK",
        description: "新闻PIK内容描述新闻PIK内容描述新闻PIK内容描述新闻PIK内容描述新闻PIK内容描述新闻PIK内容描述",
        backgroundImage: "fwqjf.jpg",
        detail: (
          <div></div>
        )
      },
      {
        id: "",
        category: "com",
        time: "20241010",
        no: "2",
        title: "新闻NCG",
        description: "新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述新闻NMG内容描述",
        backgroundImage: "fwqjf.jpg",
        detail: (
          <div></div>
        )
      },
      {
        id: "",
        category: "com",
        time: "20240929",
        no: "1",
        title: "新闻HAK",
        description: "新闻PAK内容描述新闻PAK内容描述新闻PAK内容描述新闻PAK内容描述新闻PAK内容描述",
        backgroundImage: "fwqjf.jpg",
        detail: (
          <div></div>
        )
      },
      {
        id: "",
        category: "media",
        time: "20241007",
        no: "1",
        title: "资讯BOD",
        description: "资讯BKD内容描述资讯BKD内容描述资讯BKD内容描述资讯BKD内容描述资讯BKD内容描述资讯BKD内容描述资讯BKD内容描述资讯BKD内容描述资讯BKD内容描述资讯BKD内容描述资讯BKD内容描述",
        backgroundImage: "fwqjf.jpg",
        detail: (
          <div></div>
        )
      },
      {
        id: "",
        category: "media",
        time: "20240928",
        no: "1",
        title: "资讯ABF",
        description: "资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述资讯ABF内容描述",
        backgroundImage: "fwqjf.jpg",
        detail: (
          <div></div>
        )
      },
      {
        id: "",
        category: "media",
        time: "20240925",
        no: "1",
        title: "资讯OMS",
        description: "资讯OMS内容描述资讯OMS内容描述资讯OMS内容描述资讯OMS内容描述资讯OMS内容描述资讯OMS内容描述资讯OMS内容描述资讯OMS内容描述资讯OMS内容描述资讯OMS内容描述资讯OMS内容描述资讯OMS内容描述",
        backgroundImage: "fwqjf.jpg",
        detail: (
          <div></div>
        )
      },
    ]
  },
};
