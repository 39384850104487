import React from 'react';
export const R1BannerDataSource = {
  OverPack: {
    className: 'home-page-wrapper R1Banner-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <b>招聘标语、背景图</b>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>副标题（可删除）</p>
          </span>
        ),
        className: 'title-content',
      },
      // {
      //   name: 'content2',
      //   children: (
      //     <span>
      //       <p>22222222222222</p>
      //     </span>
      //   ),
      //   className: 'title-content',
      // },
    ],
  },
  // button: {
  //   className: '',
  //   children: { a: { className: 'button', href: '#', children: '立即报名' } },
  // },
};
export const R1IntroDataSource = {
  OverPack: {
    className: 'home-page-wrapper R1Intro-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>企业文化</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <p>企业文化介绍</p>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>
              <span>
                企业规模和团队介绍
              </span>
              <br />
            </p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const R1TeamsDataSource = {
  wrapper: { className: 'home-page-wrapper R1Teams-wrapper' },
  page: { className: 'home-page R1Teams' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '团队成员简介' }],
  },
  blockTop: {
    className: 'block-top-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block-top',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'R1Teams-top-image',
              src: 'http://www.xjssd.com/owres/team/tmp1.png',
            },
            {
              name: 'title',
              className: 'R1Teams-top-title',
              children: '叶秀英',
            },
            {
              name: 'content',
              className: 'R1Teams-top-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-top-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block-top',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'R1Teams-top-image',
              src: 'http://www.xjssd.com/owres/team/tmp2.png',
            },
            { name: 'title', className: 'R1Teams-top-title', children: '韩勇' },
            {
              name: 'content',
              className: 'R1Teams-top-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-top-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block-top',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'R1Teams-top-image',
              src: 'http://www.xjssd.com/owres/team/tmp1.png',
            },
            {
              name: 'title',
              className: 'R1Teams-top-title',
              children: '叶秀英',
            },
            {
              name: 'content',
              className: 'R1Teams-top-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-top-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    gutter: 72,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'R1Teams-image',
          src: 'http://www.xjssd.com/owres/team/tmp1.png',
        },
        titleWrapper: {
          className: 'R1Teams-textWrapper',
          children: [
            { name: 'title', className: 'R1Teams-title', children: '叶秀英' },
            {
              name: 'content',
              className: 'R1Teams-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'R1Teams-image',
          src: 'http://www.xjssd.com/owres/team/tmp2.png',
        },
        titleWrapper: {
          className: 'R1Teams-textWrapper',
          children: [
            { name: 'title', className: 'R1Teams-title', children: '韩勇' },
            {
              name: 'content',
              className: 'R1Teams-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'R1Teams-image',
          src: 'http://www.xjssd.com/owres/team/tmp1.png',
        },
        titleWrapper: {
          className: 'R1Teams-textWrapper',
          children: [
            { name: 'title', className: 'R1Teams-title', children: '叶秀英' },
            {
              name: 'content',
              className: 'R1Teams-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'R1Teams-image',
          src: 'http://www.xjssd.com/owres/team/tmp1.png',
        },
        titleWrapper: {
          className: 'R1Teams-textWrapper',
          children: [
            { name: 'title', className: 'R1Teams-title', children: '叶秀英' },
            {
              name: 'content',
              className: 'R1Teams-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'R1Teams-image',
          src: 'http://www.xjssd.com/owres/team/tmp2.png',
        },
        titleWrapper: {
          className: 'R1Teams-textWrapper',
          children: [
            { name: 'title', className: 'R1Teams-title', children: '韩勇' },
            {
              name: 'content',
              className: 'R1Teams-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'R1Teams-image',
          src: 'http://www.xjssd.com/owres/team/tmp1.png',
        },
        titleWrapper: {
          className: 'R1Teams-textWrapper',
          children: [
            { name: 'title', className: 'R1Teams-title', children: '叶秀英' },
            {
              name: 'content',
              className: 'R1Teams-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'R1Teams-image',
          src: 'http://www.xjssd.com/owres/team/tmp1.png',
        },
        titleWrapper: {
          className: 'R1Teams-textWrapper',
          children: [
            { name: 'title', className: 'R1Teams-title', children: '叶秀英' },
            {
              name: 'content',
              className: 'R1Teams-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'R1Teams-image',
          src: 'http://www.xjssd.com/owres/team/tmp2.png',
        },
        titleWrapper: {
          className: 'R1Teams-textWrapper',
          children: [
            { name: 'title', className: 'R1Teams-title', children: '韩勇' },
            {
              name: 'content',
              className: 'R1Teams-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
      {
        name: 'block8',
        className: 'block',
        md: 8,
        xs: 24,
        image: {
          name: 'image',
          className: 'R1Teams-image',
          src: 'http://www.xjssd.com/owres/team/tmp1.png',
        },
        titleWrapper: {
          className: 'R1Teams-textWrapper',
          children: [
            { name: 'title', className: 'R1Teams-title', children: '叶秀英' },
            {
              name: 'content',
              className: 'R1Teams-job',
              children: '公司+职位 信息暂缺',
            },
            {
              name: 'content1',
              className: 'R1Teams-content',
              children: '职责、履历、自述或其他描述。',
            },
          ],
        },
      },
    ],
  },
};
export const R1ContentDataSource = {
  wrapper: { className: 'home-page-wrapper R1Content-wrapper' },
  page: { className: 'home-page R1Content' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>热招职位</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'R1Content-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'R1Content-block',
        md: 12,
        xs: 24,
        children: {
          className: 'R1Content-block-card',
          job: {
            className: 'R1Content-block-text',
            name: "讲解人员（共2名）",
            desc: "目前公司刚成立，为有效落实县委、县政府关于积极主动融入“XXXX”国家战略布局的要求，大力推进XXXXX科技技术有限责任公司长期发展战略，现申请招聘讲解人员2名。",
            responsibility: [
              "本科以上学历，男女不限，党员优先；",
              "有讲解经验人员优先考虑；",
              "无犯罪记录、无党内处分、行政处分等政治面貌良好的人员；",
              "此次招聘免笔试。"
            ],
            requirements: [
              "本科以上学历，男女不限，党员优先；",
              "有讲解经验人员优先考虑；",
              "无犯罪记录、无党内处分、行政处分等政治面貌良好的人员；",
              "此次招聘免笔试。"
            ],
          },
        },
      },
      {
        name: 'block1',
        className: 'R1Content-block',
        md: 12,
        xs: 24,
        children: {
          className: 'R1Content-block-card',
          job: {
            className: 'R1Content-block-text',
            name: "办公室职员（共1名）",
            desc: "目前公司刚成立，为有效落实县委、县政府关于积极主动融入“XXXX”国家战略布局的要求，大力推进XXXXX科技技术有限责任公司长期发展战略，现申请招聘办公室职员1名。",
            responsibility: [
              "本科以上学历，男女不限，党员优先；",
              "有公文撰写经验人员优先考虑；",
              "无犯罪记录、无党内处分、行政处分等政治面貌良好的人员；",
              "此次招聘免笔试。"
            ],
            requirements: [
              "本科以上学历，男女不限，党员优先；",
              "有公文撰写经验人员优先考虑；",
              "无犯罪记录、无党内处分、行政处分等政治面貌良好的人员；",
              "此次招聘免笔试。"
            ],
          },
        },
      },
      {
        name: 'block2',
        className: 'R1Content-block',
        md: 12,
        xs: 24,
        children: {
          className: 'R1Content-block-card',
          job: {
            className: 'R1Content-block-text',
            name: "司机（共1名）",
            desc: "目前公司刚成立，为有效落实县委、县政府关于积极主动融入“XXXX”国家战略布局的要求，大力推进XXXXX科技技术有限责任公司长期发展战略，现申请招聘司机1名。",
            responsibility: [
              "本科以上学历，男女不限，党员优先；",
              "熟悉县城与市区路况人员优先考虑；",
              "无犯罪记录、无党内处分、行政处分等政治面貌良好的人员。",
            ],
            requirements: [
              "本科以上学历，男女不限，党员优先；",
              "熟悉县城与市区路况人员优先考虑；",
              "无犯罪记录、无党内处分、行政处分等政治面貌良好的人员。",
            ],
          },
        },
      },
      {
        name: 'block3',
        className: 'R1Content-block',
        md: 12,
        xs: 24,
        children: {
          className: 'R1Content-block-card',
          job: {
            className: 'R1Content-block-text',
            name: "保安（共2名）",
            desc: "目前公司刚成立，为有效落实县委、县政府关于积极主动融入“XXXX”国家战略布局的要求，大力推进XXXXX科技技术有限责任公司长期发展战略，现申请招聘保安2名。",
            responsibility: [
              "本科以上学历，男女不限，党员优先；",
              "有相关从业经验人员优先考虑；",
              "无犯罪记录、无党内处分、行政处分等政治面貌良好的人员。",
            ],
            requirements: [
              "本科以上学历，男女不限，党员优先；",
              "有相关从业经验人员优先考虑；",
              "无犯罪记录、无党内处分、行政处分等政治面貌良好的人员。",
            ],
          },
        },
      },
    ],// add before here
    files: [
      {
        name: 'block0',
        className: 'R1Content-file',
        md: 4,
        xs: 24,
        children: {
          className: 'R1Content-file-card',
          file: {
            className: 'R1Content-file-text',
            name: "讲解员薪资表",
            src: "http://www.xjssd.com/owres/files/讲解员薪资表.xlsx",
          },
        },
      },
    ]
  },
};
