import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
// import { Button } from 'antd';
import { getChildrenToRender } from '../utils';

class R1Banner extends React.PureComponent {

  loadDataSource(dataSource, jsonds) {
    try {
      const titleList = dataSource.titleWrapper.children
      titleList[0].children = (
        <span>
          <b>{jsonds.title}</b>
        </span>
      )
      titleList[1].children = (
        <span>
          <p>{jsonds.subtitle}</p><br />
        </span>
      )
      // console.log('recruitment:R1Banner:dataSource', dataSource)
    } catch (err) {
      // console.log(err)
    }
    return dataSource;
  }

  render() {
    const { jsonds, isMobile, ...props } = this.props;
    let { dataSource } = props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete props.dataSource;
    delete props.isMobile;
    delete props.jsonds;
    return (
      <OverPack {...props} {...dataSource.OverPack}>
        <QueueAnim
          type="bottom"
          leaveReverse
          key="page"
          delay={[0, 100]}
          {...dataSource.titleWrapper}
        >
          {dataSource.titleWrapper.children.map(getChildrenToRender)}
        </QueueAnim>
        <TweenOne
          key="button"
          style={{ textAlign: 'center' }}
          {...dataSource.button}
          animation={{ y: 30, opacity: 0, type: 'from', delay: 300 }}
        >
          {/* <Button {...dataSource.button.children.a}>
            {dataSource.button.children.a.children}
          </Button> */}
        </TweenOne>
      </OverPack>
    );
  }
}

export default R1Banner;
