import React from 'react';
// import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne, { TweenOneGroup } from 'rc-tween-one';
import BannerAnim, { Element } from 'rc-banner-anim';
import { isImg } from '../utils';
import 'rc-banner-anim/assets/index.css';

const { BgElement } = Element;
class Banner extends React.PureComponent {

  loadDataSource(dataSource, jsonds) {
    try {
      const bannerList = dataSource.BannerAnim.children
      const list = jsonds.banners
      for (let i = 0; i < list.length; i++) {
        if (bannerList[i]) {
          bannerList[i].name = 'elem' + i
          bannerList[i].title.children = list[i].title
          bannerList[i].title.src = list[i].src
          bannerList[i].content.children = (
            <p>
              {list[i].content}
            </p>
          )
        } else {
          bannerList[i] = {
            name: 'elem' + i,
            BannerElement: { className: 'banner-user-elem' },
            textWrapper: { className: 'Banner-text-wrapper blurfilter' },
            bg: { className: 'bg bg0' },
            bg_m: { className: 'bg bg0_m' },
            title: {
              className: 'Banner-title',
              src: list[i].src,
              children: list[i].title
            },
            content: {
              className: 'Banner-content m0qfmi528hn-editor_css',
              children: (
                <p>
                  {list[i].content}
                </p>
              ),
            },
            button: { className: 'Banner-button', children: 'Learn More' },
          }
        }
      }
      if (bannerList.length > list.length)
        dataSource.BannerAnim.children = bannerList.slice(0, list.length)
      // console.log('home:Banner:dataSource', dataSource)
    } catch { }
    return dataSource;
  }

  render() {
    const { ...props } = this.props;
    const { jsonds, isMobile } = props;
    let { dataSource } = props;
    dataSource = this.loadDataSource(dataSource, jsonds)
    delete props.dataSource;
    delete props.isMobile;
    delete props.jsonds;
    const childrenToRender = dataSource.BannerAnim.children.map((item, i) => {
      const elem = item.BannerElement;
      const elemClassName = elem.className;
      delete elem.className;
      const { bg, bg_m, textWrapper, title, content } = item;
      const background = isMobile ? bg_m : bg;
      // const { button } = item;
      return (
        <Element key={i.toString()} {...elem} prefixCls={elemClassName}>
          <BgElement key="bg" {...background} />
          <QueueAnim
            type={['bottom', 'top']}
            delay={200}
            key="text"
            {...textWrapper}
          >
            <div key="logo" {...title}>
              {typeof title.children === 'string' &&
                title.children.match(isImg) ? (
                <img src={title.children} width="100%" alt="img" />
              ) : (
                title.children
              )}
            </div>
            <div key="content" {...content}>
              {content.children}
            </div>
            {/* <Button ghost key="button" {...button}>
              {button.children}
            </Button> */}
          </QueueAnim>
        </Element>
      );
    });
    return (
      <div {...props} {...dataSource.wrapper}>
        <TweenOneGroup
          key="bannerGroup"
          enter={{ opacity: 0, type: 'from' }}
          leave={{ opacity: 0 }}
          component=""
        >
          <div className="Banner-wrapper" key="wrapper">
            <BannerAnim key="BannerAnim" {...dataSource.BannerAnim}>
              {childrenToRender}
            </BannerAnim>
          </div>
        </TweenOneGroup>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="Banner-icon"
          style={{ bottom: 40 }}
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      </div>
    );
  }
}

export default Banner;
