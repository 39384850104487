import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';

import { getChildrenToRender } from '../utils';

class Partners extends React.PureComponent {

  loadDataSource(dataSource, jsonds) {
    try {
      const titleList = dataSource.titleWrapper.children
      titleList[0].children = (
        <span>
          <b>{jsonds.titleWrapper.title}</b>
        </span>
      )
      titleList[1].children = (
        <span>
          <p>{jsonds.titleWrapper.subtitle}</p><br />
        </span>
      )
      const blockList = dataSource.blockWrapper.children
      const logoList = jsonds.list
      for (let i = 0; i < logoList.length; i++) {
        if (blockList[i]) {
          blockList[i].name = 'block' + i
          blockList[i].children.children[0].children = (
            <span>
              <img alt='logo' className='Partners-logo' src={logoList[i].src} />
            </span>
          )
        } else {
          blockList[i] = {
            md: 6,
            xs: 24,
            name: 'block' + i,
            className: 'Partners-block',
            children: {
              className: 'Partners-block-group',
              children: [
                {
                  name: 'content',
                  className: 'Partners-block-content',
                  children: (
                    <span>
                      <img alt='logo' className='Partners-logo' src={logoList[i].src} />
                    </span>
                  ),
                },
              ],
            },
          }
        }
      }
      if (blockList.length > logoList.length)
        dataSource.blockWrapper.children = blockList.slice(0, logoList.length)
      // console.log('home:Partners:dataSource', dataSource)
    } catch (err) {
      // console.log(err)
    }
    return dataSource;
  }

  render() {
    const { dataSource, isMobile, ...tagProps } = this.props;
    const { blockWrapper, titleWrapper } = dataSource;
    const childrenToRender = blockWrapper.children.map((item, i) => (
      <Col {...item} key={i.toString()} style={{ padding: '0px' }}>
        <a {...item.children}>
          {item.children.children.map(getChildrenToRender)}
        </a>
      </Col>
    ));
    let dsw = dataSource.wrapper;
    if (isMobile) dsw.className = dataSource.classNameMobile;
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div {...dataSource.titleWrapper}>
            {titleWrapper.children.map(getChildrenToRender)}
          </div>
          <OverPack {...dataSource.OverPack}>
            <QueueAnim
              key="queue"
              type="bottom"
              leaveReverse
              interval={50}
              component={Row}
              {...blockWrapper}
              style={{ margin: '0px' }}
            >
              {childrenToRender}
            </QueueAnim>
          </OverPack>
        </div>
      </div>
    );
  }
}
export default Partners;
