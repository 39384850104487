import React from 'react';
export const B1BannerDataSource = {
  classNameMobile: 'home-page-wrapper B1Banner-wrapper bg2_m',
  OverPack: {
    className: 'home-page-wrapper B1Banner-wrapper bg2',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      {
        name: 'title',
        children: (
          <span>
            <b>业务中心</b>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p></p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p></p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const B1VideoDataSource = {
  wrapper: { className: 'home-page-wrapper B1Video-wrapper bg3' },
  wrapper_m: { className: 'home-page-wrapper B1Video-wrapper bg3_m' },
  page: { className: 'home-page B1Video' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <b>围绕算力经济产业提供专业的算力服务</b>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content B1Video-title-content',
        children: (
          <span>
            <p>科技想象力，数算创造力</p>
          </span>
        ),
      },
    ],
  },
  video: {
    className: 'B1Video-video',
    children: {
      video: 'https://os.alipayobjects.com/rmsportal/EejaUGsyExkXyXr.mp4',
      image: 'https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg',
    },
  },
};
export const B1Data1DataSource = {
  classNameMobile: 'home-page-wrapper B1Data1-wrapperm bg4_m',
  wrapper: { className: 'home-page-wrapper B1Data1-wrapper bg4' },
  OverPack: { className: 'home-page B1Data1', playScale: 0.3 },
  Carousel: {
    className: 'B1Data1-content',
    dots: false,
    wrapper: { className: 'B1Data1-content-wrapper blurfilter' },
    titleWrapper: {
      className: 'B1Data1-title-wrapper',
      barWrapper: {
        className: 'B1Data1-title-bar-wrapper',
        children: { className: 'B1Data1-title-bar' },
      },
      title: { className: 'B1Data1-title' },
    },
    children: [
      {
        title: {
          className: 'B1Data1-title-text',
          children: (
            <span>
              <p>计算集群一期</p>
            </span>
          ),
        },
        className: 'B1Data1-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'B1Data1-number-wrapper',
            name: 'child0',
            number: {
              className: 'B1Data1-number',
              unit: { className: 'B1Data1-unit', children: '台' },
              toText: true,
              children: '218',
            },
            children: { className: 'B1Data1-text', children: '服务器' },
          },
          {
            md: 8,
            xs: 24,
            className: 'B1Data1-number-wrapper',
            name: 'child1',
            number: {
              className: 'B1Data1-number',
              unit: { className: 'B1Data1-unit', children: 'PFlops' },
              toText: true,
              children: '150',
            },
            children: { className: 'B1Data1-text', children: '总算力' },
          },
          {
            md: 8,
            xs: 24,
            className: 'B1Data1-number-wrapper',
            name: 'child2',
            number: {
              className: 'B1Data1-number',
              unit: { className: 'B1Data1-unit', children: 'KW' },
              toText: true,
              children: '325',
            },
            children: { className: 'B1Data1-text', children: '总功耗' },
          },
        ],
      },
      {
        title: {
          className: 'B1Data1-title-text',
          children: (
            <span>
              <p>计算集群二期（建设中）</p>
            </span>
          ),
        },
        className: 'B1Data1-item',
        name: 'block1',
        children: [
          {
            md: 8,
            xs: 24,
            name: 'child0',
            className: 'B1Data1-number-wrapper',
            number: {
              className: 'B1Data1-number',
              unit: { className: 'B1Data1-unit', children: '亿元' },
              toText: true,
              children: '7.65',
            },
            children: { className: 'B1Data1-text', children: '总投资' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child1',
            className: 'B1Data1-number-wrapper',
            number: {
              className: 'B1Data1-number',
              unit: { className: 'B1Data1-unit', children: '平方米' },
              toText: true,
              children: '35000',
            },
            children: { className: 'B1Data1-text', children: '总建筑面积' },
          },
          {
            md: 8,
            xs: 24,
            name: 'child2',
            className: 'B1Data1-number-wrapper',
            number: {
              className: 'B1Data1-number',
              unit: { className: 'B1Data1-unit', children: 'PFlops' },
              toText: true,
              children: '48000',
            },
            children: { className: 'B1Data1-text', children: '预计总算力' },
          },
        ],
      },
    ],
  },
};
export const B1IntroDataSource = {
  classNameMobile: 'home-page-wrapper B1Intro-wrapper bg5_m',
  wrapper: { className: 'home-page-wrapper B1Intro-wrapper bg5' },
  OverPack: { className: 'home-page B1Intro' },
  textWrapper: { className: 'B1Intro-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>专业的服务</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>强大的算力基础资源</p>
          </span>
        ),
      },
    ],
  },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/VHGOVdYyBwuyqCx.png',
    className: 'B1Intro-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'B1Intro-icon',
        },
        title: { className: 'B1Intro-title', children: '亮点1' },
        content: {
          className: 'B1Intro-content',
          children:
            '亮点1描述111111 111111111 11111111 111111。',
        },
      },
      {
        name: 'block1',
        img: {
          className: 'B1Intro-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: { className: 'B1Intro-title', children: '亮点2' },
        content: {
          className: 'B1Intro-content',
          children:
            '亮点2描述222222 2222222 2222222222 2222222222。',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'B1Intro-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: { className: 'B1Intro-title', children: '亮点3' },
        content: {
          className: 'B1Intro-content',
          children:
            '亮点3描述33333333333 3333333333333 33333333333 3333333。',
        },
      },
    ],
  },
};
export const B1PartnersDataSource = {
  wrapper: { className: 'home-page-wrapper B1Partners-wrapper' },
  page: { className: 'home-page B1Partners' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      { name: 'title', children: '合作伙伴', className: 'title-h1' },
    ],
  },
  block: {
    className: 'img-wrapper',
    // 国家信息中心 http://www.sic.gov.cn/sic/res/img/bigLogo.png
    // 中国公共关系协会 http://images.china.cn/images1/ch/2020pra/img/logo_1.png
    // 中国交通通信信息中心 https://www.cttic.cn/template/15/center/_files/img/sy/logo.png
    // 清华大学 https://www.tsinghua.edu.cn/image/logo.png
    // 北京大学 https://www.pku.edu.cn/Uploads/Picture/2019/12/26/s5e04176fbbfa3.png https://www.pku.edu.cn/Uploads/Picture/2019/12/26/s5e04147ee4a83.png
    // 南方科技大学 https://www.sustech.edu.cn/static/images/sustech-logo-cn.png
    // 西安交通大学 https://www.xjtu.edu.cn/img/logo_pic99.png
    // 东方理工高等研究院 xxxxxxxx
    // 中国科学院计算技术研究所 https://www.ict.ac.cn/images/header_ict.png
    // 中科南京信息高铁研究院 https://nwzimg.wezhan.cn/contents/sitefiles2042/10211275/images/42256353.png
    // 江苏省未来网络创新研究院 https://www.fnii.cn/website/static/images/logo2.png
    // 交叉信息核心技术研究院 xxxxxxxx
    // 数据空间研究院 https://www.idata.ah.cn/assets/logo.1717384741061.png
    // 中广电广播电影电视设计研究院 https://www.drft.com.cn/picture/0/2212132106303456575.png
    // 电力规划设计总院 http://www.eppei.com/picture/0/2109291733523349795.png
    // 中国电气装备集团有限公司 https://www.cee-group.cn/images_list/logo.png
    // 联泰集群 https://www.lthpc.com/public/storage/upload/files/20240220/582737616a7cd880a264f9cbcc4b3011.png
    // 视联动力 https://www.visionvera.com/static/ic_logo_color.5932066f.png
    // 河南投资集团 https://res.hnic.com.cn/template/hnic/resource/images/logo1.png https://res.hnic.com.cn/template/hnic/resource/images/logo.png
    // 鉴衡认证 http://www.cgc.org.cn/images/images/logo-%E5%8F%8D%E7%99%BD.png
    // 砺算科技 https://28624713.s21i.faiusr.com/4/ABUIABAEGAAg48qxkAYoiNiMUjCsAjg9.png
    // 面壁智能 https://www.modelbest.cn/modelbest/modelbest.4VFqzLmp.svg
    // 沐曦 https://www.metax-tech.com/img/logo.svg
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://www.lthpc.com/public/storage/upload/files/20240220/582737616a7cd880a264f9cbcc4b3011.png',
            wrapper: 'imgwp',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://www.visionvera.com/static/ic_logo_color.5932066f.png',
            wrapper: 'imgwp',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://www.metax-tech.com/img/logo.svg',
            wrapper: 'imgwp',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://www.tsinghua.edu.cn/image/logo.png',
            wrapper: 'imgwp',
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://www.pku.edu.cn/Uploads/Picture/2019/12/26/s5e04147ee4a83.png',
            wrapper: 'imgwp',
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'http://images.china.cn/images1/ch/2020pra/img/logo_1.png',
            wrapper: 'imgwp',
          },
        },
      },
    ],
  },
};